

@font-face {
  font-family: 'Arvo-Bold';
  src: url(/public/Arvo/Arvo-Bold.ttf) format('truetype');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mycard {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  position:relative;
  align-items:center;
  margin:1em;
  padding: 1.5em;
  border: 1px solid lightgray; 
  border-radius: 2em;
  width: 32vh;
  height: 35vh;
  justify-content: flex-end;
}

.gameButton :hover {
  background-color: rgb(243, 243, 243);
  border-bottom: 3px solid lightgray; 
  border-right: 3px solid lightgray; 
}

.text {
  color:#282c34;
  font-family: Arvo-Bold;
  margin-top: 0.5em;
  position: absolute;
  margin-bottom: 0.5em;
}

.image {
  width: 100%;
  height: 100%; 
  object-fit: contain;
  padding:1em;
}

.img {
  border: 0;
}

.homePage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  
}

.scrollView {
    display: flex;
    justify-content: spaceAround;
    flex-direction: row;
    width: 100vw;
}
.container {
    flex: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
  }

  .Header {
    Text-align: center;
    font-size: xx-large;
    font-family: Arvo-Bold, serif;
    border-bottom-width:1px;
    padding: 0.5em;
    border-color: lightgray;
  }

  .gameButton {
    display: flex;
      justify-content: center;
      align-items: center;
      width: 90vw;
      align-self: center;

  }


